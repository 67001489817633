import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="H" transform="translate(37, 31) scale(0.50)">
        <path
          d="M 52 2.1 L 52 67.9 A 2.592 2.592 0 0 1 51.741 68.795 A 2.367 2.367 0 0 1 51.3 69.4 A 2.091 2.091 0 0 1 50.683 69.836 A 1.879 1.879 0 0 1 49.9 70 A 2.674 2.674 0 0 1 49.266 69.929 A 1.783 1.783 0 0 1 48.35 69.4 A 2.076 2.076 0 0 1 47.811 68.147 A 2.752 2.752 0 0 1 47.8 67.9 L 47.8 2.1 A 2.218 2.218 0 0 1 47.936 1.31 A 1.993 1.993 0 0 1 48.4 0.6 A 2.024 2.024 0 0 1 49.842 0.001 A 2.657 2.657 0 0 1 49.9 0 A 2.218 2.218 0 0 1 50.69 0.136 A 1.993 1.993 0 0 1 51.4 0.6 A 2.024 2.024 0 0 1 52 2.042 A 2.657 2.657 0 0 1 52 2.1 Z M 4.2 2.1 L 4.2 67.9 A 2.592 2.592 0 0 1 3.941 68.795 A 2.367 2.367 0 0 1 3.5 69.4 A 2.091 2.091 0 0 1 2.883 69.836 A 1.879 1.879 0 0 1 2.1 70 A 2.674 2.674 0 0 1 1.466 69.929 A 1.783 1.783 0 0 1 0.55 69.4 A 2.076 2.076 0 0 1 0.011 68.147 A 2.752 2.752 0 0 1 0 67.9 L 0 2.1 A 2.218 2.218 0 0 1 0.136 1.31 A 1.993 1.993 0 0 1 0.6 0.6 A 2.024 2.024 0 0 1 2.042 0.001 A 2.657 2.657 0 0 1 2.1 0 A 2.218 2.218 0 0 1 2.89 0.136 A 1.993 1.993 0 0 1 3.6 0.6 A 2.024 2.024 0 0 1 4.2 2.042 A 2.657 2.657 0 0 1 4.2 2.1 Z M 1 36 L 1.6 32 L 50.1 32 L 49.9 36 L 1 36 Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
