import { Icon } from '@components/icons';
import { srConfig } from '@config';
import sr from '@utils/sr';
import React, { useEffect, useRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';

const StyledProjectsSection = styled.section`
  h2 {
    font-size: clamp(24px, 5vw, var(--fz-heading));
  }

  .projects-grid {
    ${({ theme }) => theme.mixins.resetList};
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    grid-gap: 15px;
    position: relative;
    margin-top: 50px;

    @media (max-width: 1080px) {
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    }
  }
`;

const StyledProject = styled.li`
  position: relative;
  cursor: default;
  transition: var(--transition);

  @media (prefers-reduced-motion: no-preference) {
    &:hover,
    &:focus-within {
      .project-inner {
        transform: translateY(-7px);
      }
    }
  }

  a {
    position: relative;
    z-index: 1;
  }

  .project-inner {
    ${({ theme }) => theme.mixins.boxShadow};
    ${({ theme }) => theme.mixins.flexBetween};
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    height: 100%;
    padding: 2rem 1.75rem;
    border-radius: var(--border-radius);
    background-color: var(--light-navy);
    transition: var(--transition);
  }

  .project-top {
    ${({ theme }) => theme.mixins.flexBetween};
    margin-bottom: 35px;

    .folder {
      color: var(--green);
      svg {
        width: 40px;
        height: 40px;
      }
    }
  }

  .project-title {
    margin: 0;
    color: var(--lightest-slate);
    font-size: var(--fz-xxl);

    a {
      position: static;

      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  .project-description {
    color: var(--light-slate);
    font-size: 17px;
    margin-top: 10px;

    a {
      ${({ theme }) => theme.mixins.inlineLink};
    }
  }

  .project-tech-list {
    display: flex;
    align-items: flex-start;
    flex-grow: 1;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 0 5px 5px;
    list-style: none;

    li {
      font-family: var(--font-mono);
      font-size: var(--fz-xxs);
      line-height: 1.75;

      &:not(:last-of-type) {
        margin-right: 15px;
      }
    }
  }
`;

const Skills = () => {
  const revealTitle = useRef(null);
  const revealProjects = useRef([]);

  useEffect(() => {
    sr.reveal(revealTitle.current, srConfig());
    revealProjects.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);

  const GRID_LIMIT = 4;

  const level = {
    0: 'Basic / Learning',
    1: 'Novice',
    2: 'Beginner',
    3: 'Competent',
    4: 'Proficeient',
    5: 'Expert',
    6: 'Native',
  };

  const skills = [
    {
      Languages: {
        Python: level[5],
        JavaScript: level[5],
        Lua: level[5],
        TypeScript: level[4],
        SQL: level[4],
        NoSQL: level[4],
        Bash: level[4],
        'HTML + CSS': level[4],
        YAML: level[3],
        Java: level[2],
        'C++': level[1],
      },
    },
    {
      Tools: {
        'Visual Studio Code': level[5],
        Docker: level[5],
        Git: level[5],
        Ansible: level[5],
        Vagrant: level[5],
        Kanban: level[5],
        Jenkins: level[4],
        Terraform: level[4],
        Vim: level[4],
        Virtualisation: level[3],
      },
    },
    {
      Platforms: {
        Linux: level[6],
        Windows: level[6],
        AWS: level[5],
        MongoDB: level[5],
        'Google Cloud': level[4],
        Firebase: level[3],
        Azure: level[2],
      },
    },
    {
      Frameworks: {
        'Serverless Stack (SST)': level[5],
        Flask: level[5],
        'React.JS': level[5],
        NestJS: level[5],
        Pandas: level[5],
        'AWS-CDK': level[5],
        'AWS-SDK': level[5],
        APIs: level[5],
        Tailwind: level[4],
        'Serverless (SLS)': level[4],
        'Next.JS': level[4],
        Flutter: level[4],
      },
    },
  ];

  const projectInner = skill => {
    const title = Object.keys(skill)[0];
    const subtitles = Object.keys(skill[title]);
    const values = Object.values(skill[title]);

    return (
      <div className="project-inner">
        <header>
          <div className="project-top">
            <div className="folder">
              <Icon name="Bookmark" />
            </div>

            <h3 className="project-title">
              <span>{title}</span>
            </h3>
          </div>
        </header>

        <div>
          {subtitles.map((subtitle, i) => (
            <>
              <div
                key={`desc_${i}`}
                className="project-description"
                dangerouslySetInnerHTML={{ __html: subtitle }}
              />
              <ul key={`parent_${i}`} className="project-tech-list">
                <li key={`level_${i}`}>{values[i]}</li>
              </ul>
            </>
          ))}
        </div>
      </div>
    );
  };

  return (
    <StyledProjectsSection id="skills">
      <h2 className="numbered-heading" ref={revealTitle}>
        My Skills
      </h2>
      <ul className="projects-grid">
        {
          <TransitionGroup component={null}>
            {skills &&
              skills.map((skill, i) => (
                <CSSTransition
                  key={`trans_${i}`}
                  classNames="fadeup"
                  timeout={i >= GRID_LIMIT ? (i - GRID_LIMIT) * 300 : 300}
                  exit={false}>
                  <StyledProject
                    key={`proj_${i}`}
                    ref={el => (revealProjects.current[i] = el)}
                    style={{
                      transitionDelay: `${i >= GRID_LIMIT ? (i - GRID_LIMIT) * 100 : 0}ms`,
                    }}>
                    {projectInner(skill)}
                  </StyledProject>
                </CSSTransition>
              ))}
          </TransitionGroup>
        }
      </ul>
    </StyledProjectsSection>
  );
};

export default Skills;
