import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <g transform="translate(25, 27) scale(0.50)">
          <path
            d="M 53.2 3.1 L 53.2 66.9 A 3.664 3.664 0 0 1 52.834 68.27 A 3.45 3.45 0 0 1 52.25 69.1 A 2.829 2.829 0 0 1 50.476 69.972 A 3.942 3.942 0 0 1 50 70 Q 48.7 70 47.8 69.1 A 2.987 2.987 0 0 1 46.901 66.941 A 3.731 3.731 0 0 1 46.9 66.9 L 46.9 3.1 A 2.905 2.905 0 0 1 47.785 0.964 A 3.57 3.57 0 0 1 47.85 0.9 Q 48.8 0 50.1 0 A 3.538 3.538 0 0 1 51.11 0.138 A 2.755 2.755 0 0 1 52.35 0.9 A 3.081 3.081 0 0 1 53.2 3.08 A 3.953 3.953 0 0 1 53.2 3.1 Z M 6.3 3.1 L 6.3 66.9 Q 6.2 68.2 5.25 69.1 A 3.415 3.415 0 0 1 4.338 69.731 A 2.911 2.911 0 0 1 3.1 70 A 3.538 3.538 0 0 1 2.091 69.863 A 2.755 2.755 0 0 1 0.85 69.1 A 3.081 3.081 0 0 1 0 66.921 A 3.953 3.953 0 0 1 0 66.9 L 0 3.1 Q 0 1.8 0.9 0.9 A 3.037 3.037 0 0 1 2.858 0.014 A 4.082 4.082 0 0 1 3.2 0 A 2.983 2.983 0 0 1 5.14 0.714 A 3.935 3.935 0 0 1 5.35 0.9 A 2.971 2.971 0 0 1 6.144 2.11 A 3.062 3.062 0 0 1 6.3 3.1 Z M 2.3 37.1 L 2.7 31.3 L 50.2 31.3 L 50.1 37.1 L 2.3 37.1 Z"
            fill="currentColor"
          />
        </g>
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
